window._env_ = {
  WALLET_GDI_CHECK_TIMEOUT: "20000",
  WALLET_CONFIG_API_BASEPATH: "/webview-payment-wallet/v1",
  WALLET_CONFIG_API_ENV: "=PROD",
  WALLET_ONBOARD_SWITCH_ON_PAYMENT_PAGE: "0",
  WALLET_CONFIG_API_HOST: "https://api.platform.pagopa.it",
  WALLET_CONFIG_API_TIMEOUT: "10000",
  WALLET_PAGOPA_LOGOS_CDN: "https://assets.cdn.io.italia.it/logos/abi",
  WALLET_OUTCOME_API_BASEPATH: "/payment-wallet-outcomes/v1",
  WALLET_CONFIG_API_PM_BASEPATH: "/pp-restapi-CD",
  WALLET_CONFIG_WEBVIEW_PM_HOST: "https://wisp2.pagopa.gov.it",
  WALLET_NPG_SDK_URL: "https://xpay.nexigroup.com/monetaweb/resources/hfsdk.js",
}
